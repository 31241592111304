import TableReports from "../table/table.component";

import { mainReportTableHeaders } from "../../utils/reports/reports-table/reports-headers";
import { bottleSalesReportHeaders } from "../../utils/reports/reports-table/reports-headers";
import { barTabsReportHeaders } from "../../utils/reports/reports-table/reports-headers";

import { alphabeticalSorting } from "../../utils/reports/sorting/alphabetical-sorting";
import { countsSorting } from "../../utils/reports/sorting/counts-sorting";
import { totalSorting } from "../../utils/reports/sorting/total-sorting";
import { walkupSorting } from "../../utils/reports/sorting/walkup-sorting";

import {
  calculateMainTallyTotalCount,
  setMainTallyCount,
} from "../../utils/reports/tally-counts/main-tallys-count";

import { setBottleSales } from "../../utils/reports/tally-counts/bottle-sales-count";
import { setBarTabs } from "../../utils/reports/tally-counts/bar-tabs-count";

import "./reports.styles.scss";

const Reports = ({ reportsData }) => {
  console.log(reportsData);
  const mainReportMap = new Map();
  const bottleSalesMap = new Map();
  const barTabsMap = new Map();
  const staffMap = new Map();

  const calculateMainTallyCounts = () => {
    let totalMainTallysCount;
    let totalComps,
      totalCompBottles,
      totalBarTabs,
      totalBottleSales,
      totalPeopleEntered,
      totalReduce,
      totalWalkinFull,
      totalPrepaid;

    const resetTallysCount = (reset, tallyObject) => {
      totalComps = reset ? 0 : tallyObject?.totalComps;
      totalCompBottles = reset ? 0 : tallyObject?.totalCompBottles;
      totalBarTabs = reset ? 0 : tallyObject?.totalBarTabs;
      totalBottleSales = reset ? 0 : tallyObject?.totalBottleSales;
      totalPeopleEntered = reset ? 0 : tallyObject?.totalPeopleEntered;
      totalReduce = reset ? 0 : tallyObject?.totalReduce;
      totalWalkinFull = reset ? 0 : tallyObject?.totalWalkinFull;
      totalPrepaid = reset ? 0 : tallyObject?.totalPrepaid;
    };

    const calculateTotalTallysCount = (tallyCount) => {
      totalComps += tallyCount.totalComps;
      totalCompBottles += tallyCount.totalCompBottles;
      totalReduce += tallyCount.totalReduce;
      totalBottleSales += tallyCount.totalBottleSales;
      totalBarTabs += tallyCount.totalBarTabs;
      totalPeopleEntered += tallyCount.totalPeopleEntered;
      totalWalkinFull += tallyCount.totalWalkinFull;
      totalPrepaid += tallyCount.totalPrepaid;

      return {
        totalComps: totalComps,
        totalCompBottles: totalCompBottles,
        totalBarTabs: totalBarTabs,
        totalReduce: totalReduce,
        totalWalkinFull: totalWalkinFull,
        totalPrepaid: totalPrepaid,
        totalBottleSales: totalBottleSales,
        totalPeopleEntered: totalPeopleEntered,
        totalCount:
          totalComps +
          totalCompBottles +
          totalBarTabs +
          totalReduce +
          totalBottleSales +
          totalPeopleEntered +
          totalWalkinFull +
          totalPrepaid,
      };
    };

    const updatedReturningObject = (tallyObject, tallyCounts) => {
      const returningObject = {
        adminPromotorId: tallyObject.adminPromotorId
          ? tallyObject.adminPromotorId
          : null,
        id: tallyObject.id,
        eventId: tallyObject.event,
        promotor: tallyObject.promotor,
        userType: tallyObject.promotor.userType,
        ...tallyCounts,
      };
      return returningObject;
    };

    setMainTallyCount(
      reportsData.payLoad.promotorsMainTally,
      mainReportMap,
      resetTallysCount,
      calculateTotalTallysCount,
      updatedReturningObject
    );

    resetTallysCount(true, null);

    setMainTallyCount(
      reportsData.payLoad.staffMainTally,
      staffMap,
      resetTallysCount,
      calculateTotalTallysCount,
      updatedReturningObject
    );

    resetTallysCount(true, null);

    // for counting total of all tallys (i.e TOTAL entry at the end of the main table)
    calculateMainTallyTotalCount(
      reportsData.payLoad.promotorsMainTally,
      mainReportMap,
      calculateTotalTallysCount,
      totalMainTallysCount
    );
  };

  const calculateBottleSales = () => {
    // for promotors data
    setBottleSales(reportsData.payLoad.promotorsBottleSales, bottleSalesMap);

    // staff bottle sales are also included in the botttle sales map
    setBottleSales(reportsData.payLoad.staffBottleSales, bottleSalesMap);
  };

  const calculateBarTabs = () => {
    // for promotors data
    setBarTabs(reportsData.payLoad.promotorsBarTabs, barTabsMap);

    // staff bar tabs are also included in the bar tabs map
    setBarTabs(reportsData.payLoad.staffBarTabs, barTabsMap);
  };

  // calling the function right away:
  calculateMainTallyCounts();
  calculateBottleSales();
  calculateBarTabs();

  // converting map to arrays
  const mainReportArray = Array.from(mainReportMap);
  const staffArray = Array.from(staffMap);
  const barTabsArray = Array.from(barTabsMap);
  const bottleSalesArray = Array.from(bottleSalesMap);

  // counts sorting:
  const sortedMainTableArray = countsSorting(mainReportArray);

  // total sorting:
  totalSorting(sortedMainTableArray);

  // walkup sorting
  walkupSorting(sortedMainTableArray);

  const sortedBottleSalesArray = alphabeticalSorting(bottleSalesArray);

  const sortedBarTabsArray = alphabeticalSorting(barTabsArray);

  // Adding the team entry
  const checkPromotorsSubs = (tallyObject) => {
    if (
      (!tallyObject[1].adminPromotorId ||
        tallyObject[1].adminPromotorId === null) &&
      tallyObject[1].promotor &&
      tallyObject[1].promotor.fullName !== "WALK UP"
    ) {
      return sortedMainTableArray.some((record) => {
        return tallyObject[1]?.promotor.id === record[1].adminPromotorId;
      });
    } else {
      return false;
    }
  };

  const addTeamRow = () => {
    let indicesToAdd = [];

    sortedMainTableArray.forEach((tallyObject, index) => {
      if (
        !tallyObject[1].adminPromotorId ||
        tallyObject[1].adminPromotor === null
      ) {
        if (checkPromotorsSubs(tallyObject)) {
          indicesToAdd.push(index);
        }
      }
    });

    // I have iterated through the indices in reverse order
    for (let i = indicesToAdd.length - 1; i >= 0; i--) {
      let index = indicesToAdd[i];
      let tallyObject = sortedMainTableArray[index];

      if (tallyObject[1].promotor && tallyObject[1].promotor.fullName) {
        tallyObject[1].promotor.userType = "PROMOTOR_TEAM";
        const newTallyObject = JSON.parse(JSON.stringify(tallyObject));

        newTallyObject[0] = newTallyObject[1].promotor.fullName += "'s Team";
        newTallyObject[1].promotor.fullName += "'s Team";
        newTallyObject[1].promotor.userType = "TEAM";

        sortedMainTableArray.splice(index, 0, newTallyObject);
      }
    }
  };

  // const addTeamRow = () => {
  //   // We will track the indices where we need to add a new row
  //   let indicesToAdd = [];

  //   // First, find all indices where a new row needs to be added
  //   sortedMainTableArray.forEach((tallyObject, index) => {
  //     if (
  //       !tallyObject[1].adminPromotorId ||
  //       tallyObject[1].adminPromotor === null
  //     ) {
  //       if (checkPromotorsSubs(tallyObject)) {
  //         indicesToAdd.push(index);
  //       }
  //     }
  //   });

  //   // Now iterate through the indices in reverse order and add the new rows
  //   for (let i = indicesToAdd.length - 1; i >= 0; i--) {
  //     let index = indicesToAdd[i];
  //     let tallyObject = sortedMainTableArray[index];

  //     // Clone the tallyObject to avoid modifying the original object
  //     const newTallyObject = JSON.parse(JSON.stringify(tallyObject));
  //     // Update the fullName for the newTallyObject
  //     newTallyObject[1].promotor.fullName += " 's Team";

  //     // Insert the newTallyObject one position above the current index
  //     // This will not affect the indices of the promoters yet to be processed
  //     sortedMainTableArray.splice(index, 0, newTallyObject);
  //   }
  // };

  // const addTeamRow = () => {
  //   // Create a copy of the array to avoid issues with modifying it during iteration
  //   const copyOfSortedArray = [...sortedMainTableArray];

  //   copyOfSortedArray.forEach((tallyObject, index) => {
  //     if (
  //       !tallyObject[1].adminPromotorId ||
  //       tallyObject[1].adminPromotor === null
  //     ) {
  //       if (checkPromotorsSubs(tallyObject)) {
  //         // Clone the tallyObject to avoid modifying the original object
  //         const newTallyObject = JSON.parse(JSON.stringify(tallyObject));
  //         // Update the fullName for the newTallyObject
  //         newTallyObject[1].promotor.fullName += " 's Team";

  //         // Insert the newTallyObject at index 1 less than indexOfPromotor
  //         const newIndex = index - 1 >= 0 ? index : 0;
  //         sortedMainTableArray.splice(newIndex, 0, newTallyObject);

  //         // Skip over the newly inserted element in the next iteration to prevent processing it again
  //         index++;
  //       }
  //     }
  //   });
  // };

  // console.log(sortedMainTableArray, "array");

  // const addTeamRow = () => {
  //   sortedMainTableArray.forEach((tallyObject, index) => {
  //     if (
  //       !tallyObject[1].adminPromotorId ||
  //       tallyObject[1].adminPromotor === null
  //     ) {
  //       if (checkPromotorsSubs(tallyObject)) {
  //         console.log(checkPromotorsSubs(tallyObject));
  //         console.log(index, "index");

  //         const newTallyObject = JSON.parse(JSON.stringify(tallyObject));
  //         // Update the fullName for the newTallyObject
  //         newTallyObject[1].promotor.fullName += " 's Team";

  //         // Check if indexOfPromotor is 0, to handle insertion at the beginning
  //         if (index === 0) {
  //           sortedMainTableArray.unshift(newTallyObject); // Add to the beginning
  //         } else {
  //           // Insert the newTallyObject at indexOfPromotor (which effectively places it before the current tallyObject when the increment is considered)
  //           sortedMainTableArray.splice(index, 0, newTallyObject);
  //         }

  //         // if (indexOfPromotor !== -1) {
  //         //   return sortedMainTableArray.splice(indexOfPromotor, 0, [
  //         //     tallyObject[1].promotor.fullName + "'s Team",
  //         //     {
  //         //       ...tallyObject[1],
  //         //       promotor: {
  //         //         ...tallyObject[1].promotor,
  //         //         fullName: tallyObject[1].promotor.fullName + "'s Team",
  //         //       },
  //         //     },
  //         //   ]);
  //         // }
  //       }
  //     }
  //   });
  // };

  addTeamRow();

  return (
    <div className="all-report-tables-container">
      <div className="report-table-container">
        <TableReports
          dataArray={sortedMainTableArray}
          tableHeaders={mainReportTableHeaders}
        />
      </div>

      <div className="report-table-container">
        <TableReports
          dataArray={staffArray}
          tableHeaders={mainReportTableHeaders}
        />
      </div>

      <div className="report-table-container">
        <p className="report-table-heading"> Bottle Sales Report </p>
        <TableReports
          dataArray={sortedBottleSalesArray}
          tableHeaders={bottleSalesReportHeaders}
        />
      </div>

      <div className="report-table-container">
        <p className="report-table-heading"> Bar Tabs Report </p>
        <TableReports
          dataArray={sortedBarTabsArray}
          tableHeaders={barTabsReportHeaders}
        />
      </div>
    </div>
  );
};

export default Reports;
